import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/toast';

import {
  Container,
  DataTableHeader,
  DataTableHeaderSearchContainer,
  DataTableHeaderSearchIcon,
  Title,
  DialogContainer,
  DialogIcon,
  DialogText,
} from './styles';
import MainLayout from '../../layouts/MainLayout';

import api from '../../services/api';
import Loading from '../../components/Loading';

import { ICategory } from './interfaces';

const Categories: React.FC = () => {
  const { push } = useHistory();
  const { showToast } = useToast();

  const [data, setData] = useState<ICategory[]>([]);
  const [selectedData, setSelectedData] = useState<ICategory>();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedData] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    api.get('/admin/hymns-categories').then(response => {
      setData(response.data);
      setIsLoaded(true);
    });
  }, []);

  const handleDelete = async (id: number | undefined): Promise<void> => {
    // If any register is passed, go out of function
    if (!id) return;

    try {
      await api.delete(`/admin/hymns-categories/${id}`);

      // If not have erros, show a success toast and remove the deleted register from state
      showToast({
        severity: 'success',
        summary: 'Sucesso!',
        detail: 'Categoria apagada com sucesso!',
      });

      setData(state => state.filter((item: ICategory) => item.id !== id));
    } catch (err) {
      showToast({
        summary: 'Erro ao apagar categoria',
        detail:
          'Ocorreu um erro ao tentar apagar a categoria. Tente novamente.',
      });
    }
    setSelectedData(undefined);
  };

  /**
   * * DIALOG CODE -------------------------------------------------------------
   */

  const showDialog = (rowData: ICategory): void => {
    setSelectedData(rowData);
    setDisplayDialog(true);
  };

  const renderFooterDialog = (
    <>
      <Button
        label="Não"
        icon="pi pi-times"
        onClick={() => setDisplayDialog(false)}
        className="p-button-text"
        autoFocus
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        onClick={() => {
          setDisplayDialog(false);
          handleDelete(selectedData?.id);
        }}
      />
    </>
  );
  /** ------------------------------------------------------------------------*/

  /**
   * * DATATABLE CODE ----------------------------------------------------------
   */

  const headerDataTable = (
    <DataTableHeader>
      <Button
        onClick={() => push(`/categorias/adicionar`)}
        icon="pi pi-plus"
        className="p-button-rounded"
      />
      <DataTableHeaderSearchContainer>
        <DataTableHeaderSearchIcon />
        <InputText
          type="search"
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            setGlobalFilter(e.target.value)
          }
          placeholder="Pesquisar"
        />
      </DataTableHeaderSearchContainer>
    </DataTableHeader>
  );

  const actionsColumnDataTable = (rowData: ICategory): JSX.Element => {
    return (
      <>
        <Button
          label="Editar"
          icon="pi pi-pencil"
          className="p-button-secondary p-button-text p-button-sm p-mr-2"
          onClick={() => push(`/categorias/editar/${rowData.id}`)}
        />
        <Button
          label="Apagar"
          icon="pi pi-trash"
          className="p-button-danger p-button-text p-button-sm"
          onClick={() => showDialog(rowData)}
        />
      </>
    );
  };

  /** ------------------------------------------------------------------------*/

  return (
    <MainLayout menuItem="categorias">
      {!isLoaded && <Loading />}
      {isLoaded && (
        <Container>
          <Dialog
            header="Apagar Categoria"
            visible={displayDialog}
            modal
            footer={renderFooterDialog}
            onHide={() => setDisplayDialog(false)}
          >
            <DialogContainer>
              <DialogIcon />
              <DialogText>
                Deseja mesmo apagar a categoria{' '}
                <strong>{selectedData?.title}</strong>?
              </DialogText>
            </DialogContainer>
          </Dialog>

          <Title>Categorias</Title>

          <DataTable
            header={headerDataTable}
            className="p-datatable-striped"
            value={data}
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            loading={isLoadedData}
            sortField="id"
            sortOrder={-1}
          >
            <Column field="id" header="ID" sortable style={{ width: '20%' }} />
            <Column
              field="title"
              header="Título"
              sortable
              style={{ width: '50%' }}
            />
            <Column
              header="Ações"
              body={actionsColumnDataTable}
              style={{ width: '30%', textAlign: 'center' }}
            />
          </DataTable>
        </Container>
      )}
    </MainLayout>
  );
};

export default Categories;
