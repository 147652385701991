import styled from 'styled-components';
// import {
//   flexboxColumnMixinFunc,
//   flexboxLineMixinFunc,
// } from '../../../styles/mixins';
import {
  FieldBase,
  FormBase,
  TitleBase,
  FloatLabelBase,
  LabelBase,
  FieldSetBase,
  FieldErrorBase,
} from '../../../styles/baseStyles';

export const Container = styled.section``;

export const Form = styled(FormBase)``;

export const Title = styled(TitleBase)``;

export const Field = styled(FieldBase)``;

export const FieldError = styled(FieldErrorBase)``;

export const FieldSet = styled(FieldSetBase)`
  border-top: 1px solid ${props => props.theme.colors.borderPrimary};
  padding-top: 10px;
`;

export const FloatLabel = styled(FloatLabelBase)``;

export const Label = styled(LabelBase)``;
