import React, { createContext, useCallback, useContext, useRef } from 'react';

import { Toast } from 'primereact/toast';

export interface ToastMessage {
  severity?: 'success' | 'error' | 'info' | 'warn';
  summary: string;
  detail?: string;
  life?: number;
}

interface ToastContextData {
  showToast(message: Omit<ToastMessage, 'id'>): void;
  // tag(): JSX.Element;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

export const ToastProvider: React.FC = ({ children }) => {
  const toast = useRef() as React.MutableRefObject<Toast>;

  const showToast = useCallback(
    ({ severity = 'error', summary, detail, life = 5000 }: ToastMessage) => {
      toast.current.show({
        severity,
        summary,
        detail,
        life,
      });
    },
    [toast],
  );

  // const tag = useCallback(() => {
  //   return <Toast ref={toast} />;
  // }, [toast]);

  return (
    <ToastContext.Provider value={{ showToast }}>
      <Toast ref={toast} />
      {children}
    </ToastContext.Provider>
  );
};

export function useToast(): ToastContextData {
  const context = useContext(ToastContext);

  return context;
}
