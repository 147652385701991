import styled from 'styled-components';
import { flexboxColumnMixinFunc, flexboxLineMixinFunc } from './mixins';

export const FormBase = styled.form`
  ${flexboxColumnMixinFunc()}

  .p-tabview-panels {
    padding: 30px 0;
  }
`;

export const TitleBase = styled.h1`
  color: ${props => props.theme.colors.primaryColor};
`;

export const FieldSetBase = styled.div.attrs({
  className: 'p-field',
})`
  width: 100%;
  ${flexboxLineMixinFunc('start', 'center')}
`;

export const CheckboxFieldSetBase = styled(FieldSetBase)`
  display: block;

  -webkit-column-count: 5; /* Chrome, Safari, Opera */
  -moz-column-count: 5; /* Firefox */
  column-count: 5; /* padrão */

  @media (max-width: 1760px) {
    -webkit-column-count: 4; /* Chrome, Safari, Opera */
    -moz-column-count: 4; /* Firefox */
    column-count: 4; /* padrão */
  }

  @media (max-width: 1460px) {
    -webkit-column-count: 3; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 3; /* padrão */
  }

  @media (max-width: 1120px) {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2; /* padrão */
  }

  @media (max-width: 880px) {
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1; /* Firefox */
    column-count: 1; /* padrão */
  }
`;

export const FieldBase = styled.div.attrs({
  className: 'p-field',
})`
  ${flexboxColumnMixinFunc('start', 'flex-start')}
  min-height: 65px;
  .p-inputtext {
    min-width: 400px;
    padding: 12px;
  }
`;

export const CheckboxFieldBase = styled.div.attrs({
  className: 'p-field-checkbox',
})``;

export const FloatLabelBase = styled.span.attrs({
  className: 'p-float-label',
})``;

export const LabelBase = styled.label.attrs({
  className: 'p-d-block',
})``;

export const FieldErrorBase = styled.small.attrs({
  className: 'p-invalid p-d-block',
})``;
