import styled from 'styled-components';
import { TitleBase } from '../../styles/baseStyles';
import { flexboxLineMixinFunc } from '../../styles/mixins';

export const Container = styled.section``;

export const DataTableHeader = styled.div.attrs({
  className: 'table-header',
})`
  ${flexboxLineMixinFunc('space-between', 'center')}
`;

export const DataTableHeaderSearchContainer = styled.span.attrs({
  className: 'p-input-icon-left',
})``;

export const DataTableHeaderSearchIcon = styled.i.attrs({
  className: 'pi pi-search',
})``;

export const Title = styled(TitleBase)``;

export const DialogContainer = styled.div.attrs({
  className: 'confirmation-content p-d-flex',
})``;

export const DialogIcon = styled.i.attrs({
  className: 'pi pi-exclamation-triangle p-mr-3',
})`
  font-size: 1.5rem;
`;

export const DialogText = styled.span``;
