import styled from 'styled-components';
import { flexboxLineMixinFunc } from '../../styles/mixins';

export const Container = styled.nav`
  width: 100%;
`;
export const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;
export const MenuItem = styled.li`
  width: 100%;

  list-style: none;
  border-bottom: 1px solid${props => props.theme.colors.borderSecondary};

  &:first-child {
    border-top: 1px solid ${props => props.theme.colors.borderSecondary};
  }

  a {
    color: ${props => props.theme.colors.textOnPrimary};
    text-decoration: none;
    ${flexboxLineMixinFunc('start', 'center')}
    padding: 1rem 2rem;

    svg {
      color: ${props => props.theme.colors.textOnPrimary};
      margin-right: 1rem;

      &:hover {
        color: ${props => props.theme.colors.primaryColor};
      }
    }

    &.active {
      svg {
        color: ${props => props.theme.colors.primaryColor};
      }
      color: ${props => props.theme.colors.primaryColor};
      /* background: ${props => props.theme.colors.backgroundPrimary}; */
      background:  #282a36;
      border-left: 4px solid ${props => props.theme.colors.primaryColor};
      padding-left: 1.7rem;

      &:hover {
        a {
          color: ${props => props.theme.colors.primaryColor};
        }

        svg {
          color: ${props => props.theme.colors.primaryColor};
        }
      }
    }
  }

  &:hover {
    a {
      color: ${props => props.theme.colors.primaryColor};
      background: ${props => props.theme.colors.backgroundQuaternary};
    }

    svg {
      color: ${props => props.theme.colors.primaryColor};
    }
  }
`;
