import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  /* :root {
    
  } */

  body {        
    background: ${props => props.theme.colors.backgroundPrimary};
    color: ${props => props.theme.colors.text};
    -webkit-font-smoothing: antialiased;
    margin: 0 auto;
    overflow-x: hidden;    
  }

  body, input, button {    
    /* font-family: ${props => props.theme.font.family}; */
    
  }

  #root {    
    margin: 0 auto;    
  }

  html, body, #root {
    height: 100vh;
    width: 100%;
    max-width: 100%; 
  }

 

  /* body,
  input,
  button,
  textarea {
    font: 400 1.6rem ${props => props.theme.font.family};
    color: ${props => props.theme.colors.text};
  } */

`;
