import styled from 'styled-components';
import { flexboxLineMixinFunc } from '../../styles/mixins';
import {
  FieldBase,
  FieldErrorBase,
  FloatLabelBase,
  FormBase,
} from '../../styles/baseStyles';

export const Wrapper = styled.main`
  height: 100vh;
  width: 100vw;
  ${flexboxLineMixinFunc('center', 'center')}
  background: ${props => props.theme.colors.backgroundSecondary};
`;

export const Container = styled.div.attrs({
  className:
    'p-shadow-3 p-component p-d-flex p-jc-center p-ai-center p-flex-column',
})`
  ${flexboxLineMixinFunc('center', 'center')}
  border-radius: 10px;
  min-width: 360px;
  min-height: 400px;
  padding: 2rem 0;
  background: ${props => props.theme.colors.backgroundPrimary};

  h2 {
    margin: 2rem 0 1rem 0;
    color: ${props => props.theme.colors.primaryColor};
  }

  h3 {
    margin: 0 0 1rem 0;
    color: ${props => props.theme.colors.textSecondary};
  }
`;

export const Logo = styled.img.attrs({
  className: 'logo p-ml-2',
})``;

export const Form = styled(FormBase)``;

export const Field = styled(FieldBase)`
  width: 100%;
  min-height: 73px;

  .p-inputtext {
    min-width: 280px;
    padding: 16px;
  }
`;

export const FloatLabel = styled(FloatLabelBase)``;

export const Set = styled.div.attrs({
  className: 'p-field',
})`
  width: 100%;
  margin-top: 1rem;

  ${flexboxLineMixinFunc('center', 'center')}
`;

export const FieldError = styled(FieldErrorBase)``;
