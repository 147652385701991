import React, { useCallback, FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.png';

import { useAuth } from '../../hooks/auth';

import {
  Wrapper,
  Container,
  Logo,
  Form,
  Field,
  FloatLabel,
  Set,
  FieldError,
} from './styles';

interface SignInFormDataError {
  email?: string;
  password?: string;
}

const SignIn: React.FC = () => {
  const { signIn } = useAuth();
  const history = useHistory();
  const { showToast } = useToast();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<SignInFormDataError>({});

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      try {
        e.preventDefault();

        setErrors({});

        const loginFormData = { email, password };

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string()
            .required('Senha obrigatória')
            .min(6, 'Mínimo de 6 caracteres'),
        });

        await schema.validate(loginFormData, {
          abortEarly: false,
        });

        await signIn({
          email,
          password,
        });

        history.push('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const yupErrors = getValidationErrors(err);
          setErrors(yupErrors);
          return;
        }

        showToast({
          summary: 'Erro na autenticação',
          detail: 'Ocorreu um erro ao fazer o login. Cheque as credenciais.',
        });
      }
    },
    [signIn, history, password, email, showToast],
  );
  return (
    <Wrapper>
      <Container>
        <Logo alt="logo" src={logoImg} data-pr-tooltip="HarpaCrista-Logo" />
        <h2>Painel de Controle</h2>
        <h3>Autenticação</h3>
        <Form onSubmit={handleSubmit}>
          <Field>
            <FloatLabel>
              <InputText
                id="email"
                name="email"
                value={email}
                aria-describedby="email-help"
                className={`${
                  errors && errors.email ? 'p-invalid' : ''
                } p-d-block`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                }}
              />
              <label htmlFor="email">E-mail</label>
            </FloatLabel>
            {errors?.email && (
              <FieldError id="email-help" className="p-invalid p-d-block">
                {errors.email}
              </FieldError>
            )}
          </Field>

          <Field>
            <FloatLabel>
              <InputText
                id="password"
                name="password"
                type="password"
                aria-describedby="password-help"
                value={password}
                className={`${errors && errors.password ? 'p-invalid' : ''}`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value);
                }}
              />
              <label htmlFor="password">Senha</label>
            </FloatLabel>
            {errors?.password && (
              <FieldError id="password-help">{errors.password}</FieldError>
            )}
          </Field>
          <Set>
            <Button
              label="Acessar"
              icon="pi pi-sign-in"
              iconPos="left"
              type="submit"
            />
          </Set>
        </Form>
      </Container>
    </Wrapper>
  );
};

export default SignIn;
