import React from 'react';

import {
  Wrapper,
  Sidebar,
  LogoContainer,
  Logo,
  Title,
  MainContainer,
  Main,
} from './styles';
import Header from './Header';
import MenuSidebar from '../../components/MenuSidebar';
import logoImg from '../../assets/logo-white.png';

interface Props {
  menuItem?: 'dashboard' | 'hinos' | 'autores' | 'categorias' | undefined;
}

const MainLayout: React.FC<Props> = ({
  menuItem,

  children,
}) => {
  return (
    <Wrapper>
      <Sidebar>
        <LogoContainer>
          <Logo src={logoImg} alt="Logo Harpa Cristã" />
          <Title as="h3">Painel Administrativo</Title>
        </LogoContainer>
        <MenuSidebar menuItem={menuItem} />
      </Sidebar>
      <MainContainer>
        <Header />
        <Main>{children}</Main>
      </MainContainer>
    </Wrapper>
  );
};

export default MainLayout;
