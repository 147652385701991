import styled from 'styled-components';
import { flexboxLineMixinFunc, roundedMixinFunc } from '../../../styles/mixins';

export const Container = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.borderPrimary};
  ${flexboxLineMixinFunc('space-between', 'center')}
  height: 90px;
  padding: 0 40px;
  background: ${props => props.theme.colors.backgroundSecondary};
`;

export const Profile = styled.div`
  ${flexboxLineMixinFunc('start', 'center')}
`;

export const AvatarProfile = styled.img` 
  ${roundedMixinFunc(64)}
  border: 4px solid ${props => props.theme.colors.borderPrimary};
  margin-right: 1rem;
`;

export const Actions = styled.div`
  ${flexboxLineMixinFunc('end', 'center')}
`;
