export default {
  title: 'light',
  colors: {
    text: '#252525', // 37, 37, 37
    textSecondary: '#757575', // 117, 117, 117

    borderPrimary: '#D7D9D9', // 215, 217, 217
    borderSecondary: '#282A36', // 40, 42, 54

    primaryColor: '#FF9100', // 255, 145, 0
    textOnPrimary: '#FFFFFF', // 255, 255, 255
    secondaryColor: '#607D8B', // 108, 117, 125
    textOnSecondary: '#FFFFFF', // 255, 255, 255
    tertiaryColor: '#21222C', // 33, 34, 44
    textOnTertiary: '#FFFFFF', // 255, 255, 255

    backgroundPrimary: '#FFFFFF', // 255, 255, 255
    backgroundSecondary: '#EEF0F1', // 238, 240, 241
    backgroundTertiary: '#21222C', // 33, 34, 44
    backgroundQuaternary: '#282A36', // 40, 42, 54
  },

  font: {
    family: 'Roboto Slab',
    sizes: {
      sm: '1.2rem',
      md: '1.4rem',
      lg: '1.6rem',
      xl: '2.2rem',
    },
  },
};
