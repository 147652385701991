// import { css } from 'styled-components';

export const boxShadowMixinFunc = (
  top: number,
  left: number,
  blur: number,
  color = 'rgba(0, 0, 0, 0.5)',
  inset = false,
): string => {
  return `box-shadow: ${
    inset ? 'inset' : ''
  } ${top}px ${left}px ${blur}px ${color};`;
};

export const roundedMixinFunc = (size: number): string => {
  return `width: ${size}px;
  height: ${size}px;
  border-radius: 50%;`;
};

export const flexboxLineMixinFunc = (
  x:
    | 'start'
    | 'end'
    | 'center'
    | 'space-between'
    | 'space-evenly'
    | 'space-around' = 'start',
  y: 'flex-start' | 'center' | 'flex-end' = 'flex-start',
  wrap: 'wrap' | 'nowrap' | 'wrap-reverse' = 'nowrap',
  reverse = false,
): string => {
  return `
    display: flex;
    justify-content: ${x};
    align-items: ${y};
    flex-wrap: ${wrap};
    flex-direction: ${reverse ? 'row-reverse' : 'row'};   
  `;
};

export const flexboxColumnMixinFunc = (
  x:
    | 'start'
    | 'end'
    | 'center'
    | 'space-between'
    | 'space-evenly'
    | 'space-around' = 'start',
  y: 'flex-start' | 'center' | 'flex-end' = 'flex-start',
  wrap: 'wrap' | 'nowrap' | 'wrap-reverse' = 'nowrap',
  reverse = false,
): string => {
  return `
    display: flex;
    justify-content: ${x};
    align-items: ${y};
    flex-wrap: ${wrap};
    flex-direction: ${reverse ? 'column-reverse' : 'column'};
  `;
};
