import React from 'react';

import { Link } from 'react-router-dom';

import { AiFillHome, AiFillTags } from 'react-icons/ai';
import { BsMusicNoteList } from 'react-icons/bs';
import { FaPenNib } from 'react-icons/fa';
import { Container, MenuList, MenuItem } from './styles';

interface Props {
  menuItem?: 'dashboard' | 'hinos' | 'autores' | 'categorias' | undefined;
}

const MenuSidebar: React.FC<Props> = ({ menuItem }) => {
  // const { push } = useHistory();

  return (
    <Container>
      <MenuList>
        <MenuItem>
          <Link
            to="/dashboard"
            className={menuItem === 'dashboard' ? 'active' : ''}
          >
            <AiFillHome />
            Dashboard
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="/hinos" className={menuItem === 'hinos' ? 'active' : ''}>
            <BsMusicNoteList />
            Hinos
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to="/autores"
            className={menuItem === 'autores' ? 'active' : ''}
          >
            <FaPenNib />
            Autores
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to="/categorias"
            className={menuItem === 'categorias' ? 'active' : ''}
          >
            <AiFillTags />
            Categorias
          </Link>
        </MenuItem>
      </MenuList>
    </Container>
  );
};

export default MenuSidebar;
