import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel, TabViewProps } from 'primereact/tabview';

import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';
import {
  Container,
  Field,
  FieldError,
  FieldSet,
  FloatLabel,
  Form,
  Label,
  Title,
} from './styles';
import MainLayout from '../../../layouts/MainLayout';
import Loading from '../../../components/Loading';
import { IFormDataError } from '../interfaces';

const AuthorsAdd: React.FC = () => {
  const { push, goBack } = useHistory();
  const { showToast } = useToast();

  const [errors, setErrors] = useState<IFormDataError>({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState<TabViewProps>({ activeIndex: 0 });

  /** INPUTS */
  const [name, setName] = useState('');
  const [initials, setInitials] = useState('');

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      try {
        e.preventDefault();

        // erase error
        setErrors({});

        const data = { name, initials };

        // Form Validation settings
        const schema = Yup.object().shape({
          name: Yup.string()
            .min(2, 'O nome deve conter no mínimo de 2 caracteres')
            .max(45, 'O nome deve conter no mínimo de 45 caracteres')
            .matches(
              /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/,
              'O nome deve conter apenas letras',
            ),
          initials: Yup.string()
            .min(2, 'As iniciais devem conter no mínimo de 2 caracteres')
            .max(16, 'As iniciais devem conter no mínimo de 16 caracteres')
            .matches(
              /^[aA-zZ*.']+$/,
              'O nome deve conter apenas letras, pontos e asteriscos',
            ),
        });

        // validate form
        await schema.validate(data, {
          abortEarly: false,
        });

        // Save register on API
        await api.post(`/admin/hymns-authors`, {
          name: data.name,
          initials: data.initials,
        });

        showToast({
          severity: 'success',
          summary: 'Sucesso!',
          detail: 'Autor cadastrado com sucesso!',
        });

        push('/autores');
      } catch (err) {
        // Yup Error
        if (err instanceof Yup.ValidationError) {
          const yupErrors = getValidationErrors(err);
          setErrors(yupErrors);
          return;
        }

        // Especific errors..
        if (
          err.response &&
          err.response.data.message === 'Author already exists.'
        ) {
          showToast({
            summary: 'Autor já cadastrado.',
            detail: 'Já existe um autor com este nome. Informe outro nome.',
          });
          return;
        }

        // General errors...
        showToast({
          summary: 'Erro ao cadastrar Autor.',
          detail:
            'Ocorreu um erro ao cadastrar o autor. Verifique os dados informados e tente novamente.',
        });
      }
    },
    [name, initials, push, showToast],
  );

  return (
    <MainLayout menuItem="autores">
      {!isLoaded && <Loading />}
      {isLoaded && (
        <Container>
          <Title>Adicionar Autor</Title>
          <Form onSubmit={handleSubmit}>
            <TabView
              activeIndex={activeTab.activeIndex}
              onTabChange={e => setActiveTab({ activeIndex: e.index })}
              style={{ width: '100%' }}
            >
              <TabPanel header="Principal">
                <Field>
                  <FloatLabel>
                    <InputText
                      id="name"
                      type="text"
                      aria-describedby="name-help"
                      className={`${
                        errors && errors.name ? 'p-invalid' : ''
                      } p-d-block`}
                      value={name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setName(e.target.value);
                      }}
                    />
                    <Label htmlFor="name">Nome</Label>
                  </FloatLabel>
                  {errors?.name && (
                    <FieldError id="name-help">{errors.name}</FieldError>
                  )}
                </Field>
                <Field>
                  <FloatLabel>
                    <InputText
                      id="initials"
                      type="text"
                      aria-describedby="initials-help"
                      className={`${
                        errors && errors.initials ? 'p-invalid' : ''
                      } p-d-block`}
                      value={initials}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setInitials(e.target.value);
                      }}
                    />
                    <Label htmlFor="initials">Inicias</Label>
                  </FloatLabel>
                  {errors?.initials && (
                    <FieldError id="initials-help">
                      {errors.initials}
                    </FieldError>
                  )}
                </Field>
              </TabPanel>
            </TabView>
            <FieldSet>
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button p-mr-2"
                type="submit"
              />

              <Button
                label="Cancelar"
                icon="pi pi-times"
                className=" p-button-outlined p-button-secondary"
                type="button"
                onClick={goBack}
              />
            </FieldSet>
          </Form>
        </Container>
      )}
    </MainLayout>
  );
};

export default AuthorsAdd;
