import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/toast';

import {
  Container,
  DataTableHeader,
  DataTableHeaderSearchContainer,
  DataTableHeaderSearchIcon,
  Title,
  DialogContainer,
  DialogIcon,
  DialogText,
} from './styles';
import MainLayout from '../../layouts/MainLayout';

import api from '../../services/api';
import Loading from '../../components/Loading';

import { IHymn } from './interfaces';

const Hymns: React.FC = () => {
  const { push } = useHistory();
  const { showToast } = useToast();

  const [data, setData] = useState<IHymn[]>([]);
  const [selectedData, setSelectedData] = useState<IHymn>();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedData] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    api
      .get('/admin/hymns', {
        params: { page: 1, perPage: 650 },
      })
      .then(response => {
        const parsedHymns = response.data.map((item: IHymn) => {
          const hymn = {
            id: item.id,
            title: item.title,
            num_hymn: item.num_hymn,
            views: item.views,
            authors: item.hymn_authors
              ? item.hymn_authors
                  .map(subitem => subitem.authors.name)
                  .join(', ')
              : '',
            categories: item.hymn_categories
              ? item.hymn_categories
                  .map(subitem => subitem.category?.title)
                  .join(', ')
              : '',
          };

          return hymn;
        });

        setData(parsedHymns);
        setIsLoaded(true);
      });
  }, []);

  const handleDelete = async (id: number | undefined): Promise<void> => {
    // If any register is passed, go out of function
    if (!id) return;

    showToast({
      severity: 'info',
      summary: 'Informação',
      detail: 'Não é possível apagar os hinos',
    });

    // try {
    //   await api.delete(`/admin/hymns/${id}`);

    //   // If not have erros, show a success toast and remove the deleted register from state
    //   showToast({
    //     severity: 'success',
    //     summary: 'Sucesso!',
    //     detail: 'Hino apagado com sucesso!',
    //   });

    //   setData(state => state.filter((item: IHymn) => item.id !== id));
    // } catch (err) {
    //   showToast({
    //     summary: 'Erro ao apagar hino',
    //     detail: 'Ocorreu um erro ao tentar apagar o hino. Tente novamente.',
    //   });
    // }
    setSelectedData(undefined);
  };

  /**
   * * DIALOG CODE -------------------------------------------------------------
   */

  const showDialog = (rowData: IHymn): void => {
    setSelectedData(rowData);
    setDisplayDialog(true);
  };

  const renderFooterDialog = (
    <>
      <Button
        label="Não"
        icon="pi pi-times"
        onClick={() => setDisplayDialog(false)}
        className="p-button-text"
        autoFocus
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        onClick={() => {
          setDisplayDialog(false);
          handleDelete(selectedData?.id);
        }}
      />
    </>
  );
  /** ------------------------------------------------------------------------*/

  /**
   * * DATATABLE CODE ----------------------------------------------------------
   */

  const headerDataTable = (
    <DataTableHeader>
      <Button
        icon="pi pi-plus"
        className="p-button-rounded"
        // onClick={() => push(`/hinos/adicionar`)}
        onClick={() =>
          showToast({
            severity: 'info',
            summary: 'Informação',
            detail: 'Adicionar hino não disponível',
          })
        }
      />
      <DataTableHeaderSearchContainer>
        <DataTableHeaderSearchIcon />
        <InputText
          type="search"
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            setGlobalFilter(e.target.value)
          }
          placeholder="Pesquisar"
        />
      </DataTableHeaderSearchContainer>
    </DataTableHeader>
  );

  const actionsColumnDataTable = (rowData: IHymn): JSX.Element => {
    return (
      <>
        <Button
          label="Editar"
          icon="pi pi-pencil"
          className="p-button-secondary p-button-text p-button-sm p-mr-2"
          onClick={() => push(`/hinos/editar/${rowData.id}`)}
        />
        <Button
          label="Apagar"
          icon="pi pi-trash"
          className="p-button-danger p-button-text p-button-sm"
          onClick={() => showDialog(rowData)}
        />
      </>
    );
  };

  /** ------------------------------------------------------------------------*/

  return (
    <MainLayout menuItem="hinos">
      {!isLoaded && <Loading />}
      {isLoaded && (
        <Container>
          <Dialog
            header="Apagar Hino"
            visible={displayDialog}
            modal
            footer={renderFooterDialog}
            onHide={() => setDisplayDialog(false)}
          >
            <DialogContainer>
              <DialogIcon />
              <DialogText>
                Deseja mesmo apagar o hino{' '}
                <strong>{selectedData?.title}</strong>?
              </DialogText>
            </DialogContainer>
          </Dialog>

          <Title>Hinos</Title>

          <DataTable
            header={headerDataTable}
            className="p-datatable-striped"
            value={data}
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
            rows={50}
            rowsPerPageOptions={[10, 25, 50, 100]}
            globalFilter={globalFilter}
            emptyMessage="Nenhum registro encontrado."
            loading={isLoadedData}
            sortField="id"
            sortOrder={1}
          >
            <Column field="id" header="ID" sortable style={{ width: '10%' }} />
            <Column
              field="title"
              header="Título"
              sortable
              style={{ width: '30%' }}
            />
            <Column
              field="authors"
              header="Autores"
              sortable
              style={{ width: '20%' }}
            />
            <Column
              field="categories"
              header="Categorias"
              sortable
              style={{ width: '20%' }}
            />
            <Column
              header="Ações"
              body={actionsColumnDataTable}
              style={{ width: '20%', textAlign: 'center' }}
            />
          </DataTable>
        </Container>
      )}
    </MainLayout>
  );
};

export default Hymns;
