export default {
  title: 'dark',
  colors: {
    text: '#FFFFFF', // 255, 255, 255
    textSecondary: '#B9BBBE ', // 185, 187, 190

    borderPrimary: '#747686', // 215, 217, 217
    borderSecondary: '#282A36', // 40, 42, 54

    primaryColor: '#FF6000', // 255, 96, 0
    textOnPrimary: '#FFFFFF', // 255, 255, 255
    secondaryColor: '#607D8B', // 96, 125, 139
    textOnSecondary: '#FFFFFF', // 255, 255, 255
    tertiaryColor: '#191A21', // 26, 26, 33
    textOnTertiary: '#FFFFFF', // 255, 255, 255

    backgroundPrimary: '#282A36', // 40, 42, 54
    backgroundSecondary: '#21222C', // 33, 34, 44
    backgroundTertiary: '#EEF0F1', // 238, 240, 241
    backgroundQuaternary: '#C4C2C0', // 196, 194, 192
  },

  font: {
    family: 'Roboto Slab',
    sizes: {
      sm: '1.2rem',
      md: '1.4rem',
      lg: '1.8rem',
      xl: '2.2rem',
    },
  },
};
