import React from 'react';
// import { useHistory } from 'react-router-dom';
import { Container } from './styles';

// interface SignUpFormData {
//   name: string;
//   email: string;
//   password: string;
// }

const SignUp: React.FC = () => {
  // const history = useHistory();

  // const handleSubmit = useCallback(() => {}, []);
  return (
    <Container>
      <h1>Cadastrar</h1>
    </Container>
  );
};

export default SignUp;
