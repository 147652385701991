import styled from 'styled-components';
import { TitleBase } from '../../styles/baseStyles';
import { flexboxColumnMixinFunc } from '../../styles/mixins';

export const Wrapper = styled.div.attrs({
  className: 'p-component p-grid',
})`
  /* fix total width */
  width: calc(100vw - 0.5rem);

  height: 100vh;
  margin: 0 !important;
`;

export const Sidebar = styled.aside.attrs({
  className: 'p-col-fixed',
})`
  width: 260px;
  background: ${props => props.theme.colors.backgroundTertiary};
  padding: 0;
`;

export const LogoContainer = styled.div`
  ${flexboxColumnMixinFunc('center', 'center')}

  height: 90px;
  width: 100%;
  background: #282a36;
  border-bottom: 1px solid #747686;
  margin-bottom: 40px;
`;

export const Logo = styled.img``;

export const Title = styled(TitleBase)`
  color: ${props => props.theme.colors.textSecondary};
  font-weight: 400;
  font-size: 1rem;
  margin: 0.5rem 0 0 0;
`;

export const MainContainer = styled.div.attrs({
  className: 'p-col',
})`
  padding: 0;
`;
export const Main = styled.main`
  padding: 20px 40px;
`;
