import React from 'react';

import { Container, Title } from './styles';

import MainLayout from '../../layouts/MainLayout';

const Dashboard: React.FC = () => {
  return (
    <MainLayout menuItem="dashboard">
      <Container>
        <Title>Dashboard</Title>
      </Container>
    </MainLayout>
  );
};

export default Dashboard;
