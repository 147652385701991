import styled from 'styled-components';
// import {
//   flexboxColumnMixinFunc,
//   flexboxLineMixinFunc,
// } from '../../../styles/mixins';
import {
  FieldBase,
  FormBase,
  TitleBase,
  FloatLabelBase,
  LabelBase,
  FieldSetBase,
  FieldErrorBase,
  CheckboxFieldSetBase,
  CheckboxFieldBase,
} from '../../../styles/baseStyles';

export const Container = styled.section``;

export const Form = styled(FormBase)``;

export const Title = styled(TitleBase)``;

export const Field = styled(FieldBase)`
  .p-inputtext {
    width: 100%;
    padding: 12px;
  }

  .p-autocomplete {
    width: 100%;
    min-height: 120px;
  }

  .p-autocomplete-multiple-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow: auto;
    overflow-wrap: normal;
  }
`;

export const FieldError = styled(FieldErrorBase)``;

export const FieldErrorCheckbox = styled(FieldErrorBase)`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid red;
  color: red;
`;

export const FieldSet = styled(FieldSetBase)`
  border-top: 1px solid ${props => props.theme.colors.borderPrimary};
  padding-top: 10px;
`;

export const CheckboxFieldSet = styled(CheckboxFieldSetBase)``;

export const CheckboxField = styled(CheckboxFieldBase)``;

export const FloatLabel = styled(FloatLabelBase)`
  width: 100%;
`;

export const Label = styled(LabelBase)``;
