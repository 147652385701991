import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';
import { Editor } from 'primereact/editor';
import { Checkbox } from 'primereact/checkbox';
import { TabView, TabPanel, TabViewProps } from 'primereact/tabview';

import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';
import {
  Container,
  Field,
  FieldError,
  FieldSet,
  FloatLabel,
  Form,
  Label,
  Title,
  CheckboxFieldSet,
  CheckboxField,
  FieldErrorCheckbox,
} from './styles';
import MainLayout from '../../../layouts/MainLayout';
import Loading from '../../../components/Loading';
import {
  IFormDataError,
  IHymn,
  IAuthorList,
  ICategoriesList,
} from '../interfaces';

const HymnsEdit: React.FC = () => {
  const { push, goBack } = useHistory();
  const { showToast } = useToast();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<IHymn>();

  const [errors, setErrors] = useState<IFormDataError>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState<TabViewProps>({ activeIndex: 0 });

  /** INPUTS */
  const [title, setTitle] = useState('');
  const [number, setNumber] = useState(0);
  const [authorsList, setAuthorsList] = useState<IAuthorList[]>([]);
  const [filteredAuthors, setFilteredAuthors] = useState<IAuthorList[]>();
  const [selectedAuthors, setSelectedAuthors] = useState<IAuthorList[]>();
  const [categoriesList, setCategoriesList] = useState<ICategoriesList[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<
    ICategoriesList[]
  >([]);
  const [body, setBody] = useState('');

  useEffect(() => {
    api.get(`/admin/hymns/${id}`).then(response => {
      const result: IHymn = response.data;
      setData(result);
      setNumber(result.num_hymn);
      setTitle(result.title);
      setBody(result.body);

      const authorsValue = result?.hymn_authors
        ? result.hymn_authors.map(item => {
            return {
              id: item.authors.id,
              name: item.authors.name,
              initials: item.authors.initials,
            };
          })
        : [];
      setSelectedAuthors(authorsValue);

      const categoriesValue = result?.hymn_categories
        ? result.hymn_categories.map(item => {
            return {
              id: item.category.id,
              title: item.category.title,
              slug: item.category.slug,
            };
          })
        : [];
      setSelectedCategories(categoriesValue);

      setIsLoaded(true);
    });

    api.get('hymns-categories').then(response => {
      const result = response.data.map((category: ICategoriesList) => {
        const categorySwitch = {
          id: category.id,
          title: category.title,
          slug: category.slug,
        };
        return categorySwitch;
      });
      setCategoriesList(result);
    });

    api.get('hymns-authors').then(response => {
      const result = response.data;
      setAuthorsList(result);
    });

    setIsLoaded(true);
  }, [id]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      try {
        e.preventDefault();

        // erase error
        setErrors({});

        const dataToValidate = {
          title,
          number,
          body,
          authors: selectedAuthors,
          categories: selectedCategories,
        };

        // // Form Validation settings
        const schema = Yup.object().shape({
          title: Yup.string()
            .min(2, 'O título deve conter no mínimo de 2 caracteres')
            .max(60, 'O título deve conter no mínimo de 60 caracteres')
            .matches(
              /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'’!,?:\-\s]+$/,
              'O título deve conter apenas letras',
            ),
          number: Yup.number()
            .positive()
            .integer()
            .min(1, 'O número informado deve ser entre 1 e 640')
            .max(640, 'O número informado deve ser entre 1 e 640'),
          authors: Yup.array()
            .min(
              1,
              'Você deve selecionar ao menos 1 autor (Autor Desconhecido se não souber)',
            )
            .max(4, 'São permitidos apenas 4 autores'),
          categories: Yup.array().max(
            5,
            'São permitidos no máximo 5 categorias',
          ),
          body: Yup.string().min(
            10,
            'O corpo do texto deve conter ao menos 10 caracteres',
          ),
        });

        // validate form
        await schema.validate(dataToValidate, {
          abortEarly: false,
        });

        const hymnData = {
          title,
          num_hymn: Number(number),
          hymn_authors: selectedAuthors?.map(author => author.id),
          hymn_categories: selectedCategories?.map(category => category.id),
          body,
          verses: data?.verses,
          choruses: data?.choruses,
          num_choruses: data?.num_choruses,
        };

        // Save register on API
        await api.put(`/admin/hymns/${id}`, hymnData);

        showToast({
          severity: 'success',
          summary: 'Sucesso!',
          detail: 'Hino alterado com sucesso!',
        });

        push('/hinos');
      } catch (err) {
        // Yup Error
        if (err instanceof Yup.ValidationError) {
          const yupErrors = getValidationErrors(err);
          setErrors(yupErrors);
          return;
        }

        // Especific errors..
        if (
          err.response &&
          err.response.data.message === 'Hymn already exists.'
        ) {
          showToast({
            summary: 'Hino já cadastrada.',
            detail: 'Já existe um hino com este título. Informe outro título.',
          });
          return;
        }

        // General errors...
        showToast({
          summary: 'Erro ao alterar Hino.',
          detail:
            'Ocorreu um erro ao alterar o hino. Verifique os dados informados e tente novamente.',
        });
      }
    },
    [
      id,
      title,
      body,
      number,
      selectedAuthors,
      selectedCategories,
      data,
      showToast,
      push,
    ],
  );

  function searchAuthor(word: string): void {
    setTimeout(() => {
      let filteredData;
      if (!word.trim().length) {
        filteredData = authorsList;
      } else {
        filteredData = authorsList.filter(author => {
          return author.name.toLowerCase().startsWith(word.toLowerCase());
        });
      }

      setFilteredAuthors(filteredData);
    }, 250);
  }

  const changeCategories = useCallback(
    e => {
      let selectedData;

      if (e.checked) {
        selectedData = [...selectedCategories, e.target.value];
      } else {
        selectedData = selectedCategories.filter(
          (element: ICategoriesList) => element.id !== e.target.value.id,
        );
      }

      setSelectedCategories(selectedData);
    },
    [selectedCategories],
  );

  return (
    <MainLayout menuItem="hinos">
      {!isLoaded && <Loading />}
      {isLoaded && (
        <Container>
          <Title>Editar Hino</Title>
          <Form onSubmit={handleSubmit}>
            <TabView
              activeIndex={activeTab.activeIndex}
              onTabChange={e => setActiveTab({ activeIndex: e.index })}
              style={{ width: '100%' }}
            >
              <TabPanel header="Principal">
                <div className="p-grid">
                  <div className="p-col-6">
                    <Field>
                      <FloatLabel>
                        <InputNumber
                          id="number"
                          min={1}
                          max={640}
                          aria-describedby="number-help"
                          className={`${
                            errors && errors.number ? 'p-invalid' : ''
                          } p-d-block`}
                          value={Number(number)}
                          onValueChange={e => {
                            setNumber(e.target.value);
                          }}
                        />
                        <Label htmlFor="number">Número</Label>
                      </FloatLabel>
                      {errors?.number && (
                        <FieldError id="number-help">
                          {errors.number}
                        </FieldError>
                      )}
                    </Field>
                    <Field>
                      <FloatLabel>
                        <InputText
                          id="title"
                          type="text"
                          aria-describedby="title-help"
                          className={`${
                            errors && errors.title ? 'p-invalid' : ''
                          } p-d-block`}
                          value={title}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setTitle(e.target.value);
                          }}
                        />
                        <Label htmlFor="title">Título</Label>
                      </FloatLabel>
                      {errors?.title && (
                        <FieldError id="title-help">{errors.title}</FieldError>
                      )}
                    </Field>
                    <Field>
                      <FloatLabel>
                        <AutoComplete
                          value={selectedAuthors}
                          suggestions={filteredAuthors}
                          completeMethod={e => searchAuthor(e.query)}
                          field="name"
                          id="authors"
                          aria-describedby="authors-help"
                          multiple
                          onChange={e => setSelectedAuthors(e.value)}
                        />
                        <Label htmlFor="authors">Autor(es):</Label>
                      </FloatLabel>
                      {errors?.authors && (
                        <FieldError id="authors-help">
                          {errors.authors}
                        </FieldError>
                      )}
                    </Field>
                  </div>
                  <div className="p-col-6">
                    <Editor
                      style={{ height: '420px' }}
                      value={body}
                      onTextChange={e =>
                        setBody(e.htmlValue ? e.htmlValue : '')
                      }
                    />
                    {errors?.body && (
                      <FieldError id="authors-help">{errors.body}</FieldError>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Categorias">
                {errors?.categories && (
                  <FieldErrorCheckbox id="category-help">
                    {errors.categories}
                  </FieldErrorCheckbox>
                )}
                <CheckboxFieldSet>
                  {categoriesList.map(category => {
                    return (
                      <CheckboxField key={category.id}>
                        <Checkbox
                          inputId={category.slug}
                          name="category"
                          value={category}
                          onChange={e => changeCategories(e)}
                          checked={selectedCategories?.some(
                            item => item.id === category.id,
                          )}
                        />
                        <label htmlFor={category.slug}>{category.title}</label>
                      </CheckboxField>
                    );
                  })}
                </CheckboxFieldSet>
              </TabPanel>
              <TabPanel header="Mídias">Nenhuma mídia cadastrada.</TabPanel>
            </TabView>
            <FieldSet>
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button p-mr-2"
                type="submit"
              />

              <Button
                label="Cancelar"
                icon="pi pi-times"
                className=" p-button-outlined p-button-secondary"
                onClick={goBack}
                type="button"
              />
            </FieldSet>
          </Form>
        </Container>
      )}
    </MainLayout>
  );
};

export default HymnsEdit;
