import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel, TabViewProps } from 'primereact/tabview';

import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';
import {
  Container,
  Field,
  FieldError,
  FieldSet,
  FloatLabel,
  Form,
  Label,
  Title,
} from './styles';
import MainLayout from '../../../layouts/MainLayout';
import Loading from '../../../components/Loading';
import { IFormDataError, ICategory } from '../interfaces';

const CategoriesEdit: React.FC = () => {
  const { push, goBack } = useHistory();
  const { showToast } = useToast();
  const { id } = useParams<{ id: string }>();

  const [errors, setErrors] = useState<IFormDataError>({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState<TabViewProps>({ activeIndex: 0 });

  /** INPUTS */
  const [title, setTitle] = useState('');

  useEffect(() => {
    api.get(`/admin/hymns-categories/${id}`).then(response => {
      const result: ICategory = response.data;
      // setData(result);

      setTitle(result.title);
    });

    setIsLoaded(true);
  }, [id]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      try {
        e.preventDefault();

        // erase error
        setErrors({});

        const data = { title };

        // Form Validation settings
        const schema = Yup.object().shape({
          title: Yup.string()
            .min(2, 'O título deve conter no mínimo de 2 caracteres')
            .max(45, 'O título deve conter no mínimo de 45 caracteres')
            .matches(
              /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]+$/,
              'O nome deve conter apenas letras',
            ),
        });

        // validate form
        await schema.validate(data, {
          abortEarly: false,
        });

        // Save register on API
        await api.put(`/admin/hymns-categories/${id}`, {
          title: data.title,
        });

        showToast({
          severity: 'success',
          summary: 'Sucesso!',
          detail: 'Categoria alterada com sucesso!',
        });

        push('/categorias');
      } catch (err) {
        // Yup Error
        if (err instanceof Yup.ValidationError) {
          const yupErrors = getValidationErrors(err);
          setErrors(yupErrors);
          return;
        }

        // Especific errors..
        if (
          err.response &&
          err.response.data.message === 'Category already exists.'
        ) {
          showToast({
            summary: 'Categoria já cadastrada.',
            detail:
              'Já existe uma categoria com este título. Informe outro título.',
          });
          return;
        }

        // General errors...
        showToast({
          summary: 'Erro ao alterar Categoria.',
          detail:
            'Ocorreu um erro ao alterar a categoria. Verifique os dados informados e tente novamente.',
        });
      }
    },
    [id, title, push, showToast],
  );

  return (
    <MainLayout menuItem="categorias">
      {!isLoaded && <Loading />}
      {isLoaded && (
        <Container>
          <Title>Editar Categoria</Title>
          <Form onSubmit={handleSubmit}>
            <TabView
              activeIndex={activeTab.activeIndex}
              onTabChange={e => setActiveTab({ activeIndex: e.index })}
              style={{ width: '100%' }}
            >
              <TabPanel header="Principal">
                <Field>
                  <FloatLabel>
                    <InputText
                      id="title"
                      type="text"
                      aria-describedby="title-help"
                      className={`${
                        errors && errors.title ? 'p-invalid' : ''
                      } p-d-block`}
                      value={title}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setTitle(e.target.value);
                      }}
                    />
                    <Label htmlFor="title">Título</Label>
                  </FloatLabel>
                  {errors?.title && (
                    <FieldError id="title-help">{errors.title}</FieldError>
                  )}
                </Field>
              </TabPanel>
            </TabView>
            <FieldSet>
              <Button
                label="Salvar"
                icon="pi pi-check"
                className="p-button p-mr-2"
                type="submit"
              />

              <Button
                label="Cancelar"
                icon="pi pi-times"
                className=" p-button-outlined p-button-secondary"
                onClick={goBack}
                type="button"
              />
            </FieldSet>
          </Form>
        </Container>
      )}
    </MainLayout>
  );
};

export default CategoriesEdit;
