import React from 'react';

import { Button } from 'primereact/button';
import { useAuth } from '../../../hooks/auth';

import { Container, AvatarProfile, Profile, Actions } from './styles';

const Header: React.FC = () => {
  const { signOut, user } = useAuth();

  return (
    <Container>
      <Profile>
        <AvatarProfile src={user.avatar_url} alt={user.name} />
        <span>
          Olá, <strong>{user.name}</strong>!
        </span>
      </Profile>
      <Actions>
        <Button
          icon="pi pi-user-edit"
          className="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
        />
        <Button
          onClick={signOut}
          icon="pi pi-sign-out"
          className="p-button-rounded"
        />
      </Actions>
    </Container>
  );
};

export default Header;
